import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/paratriennale/paratriennale/loveletter/src/templates/works-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TextWorkFrame = makeShortcode("TextWorkFrame");
const Caption = makeShortcode("Caption");
const BpHr = makeShortcode("BpHr");
const LinkButton = makeShortcode("LinkButton");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`松本美枝子`}</h1>
    <p>{`ピンホールレンズを使って撮影された写真作品が４点あります。写真には、海が見える景色や色とりどりの花が咲く草原、洋服を着せたトルソーのある室内の風景などが収められています。松本美枝子さんは、「あなたが人に見せたいと思う風景」について４人の方から話を聞いて、その風景を想像し写真を撮りに行きました。全体に少しだけピントが外れたような、不思議な風合いの写真には、見知らぬ風景でありながら、どこか懐かしさのようなものも感じられます。`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1280px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "66.5625%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAgP/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAaZQpQ+Bof/EABoQAAIDAQEAAAAAAAAAAAAAAAECAAMREjP/2gAIAQEAAQUCB5L2bbHOKvpP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFhEAAwAAAAAAAAAAAAAAAAAAARAx/9oACAECAQE/ATV//8QAGBAAAgMAAAAAAAAAAAAAAAAAEBEAAUH/2gAIAQEABj8CcWGh/8QAGBAAAwEBAAAAAAAAAAAAAAAAAAEREHH/2gAIAQEAAT8hMMQkxZY97Z//2gAMAwEAAgADAAAAEL/f/8QAFhEAAwAAAAAAAAAAAAAAAAAAARAx/9oACAEDAQE/EBF//8QAFREBAQAAAAAAAAAAAAAAAAAAEDH/2gAIAQIBAT8Qmf/EABsQAQACAwEBAAAAAAAAAAAAAAERIQAQMUHB/9oACAEBAAE/EBBsB2pyxlYKvSE9xFvvxr//2Q==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/41f9e889e80b620da046fd13c71397de/ec4f2/mieko-matsumoto.webp 320w", "/static/41f9e889e80b620da046fd13c71397de/74ed4/mieko-matsumoto.webp 640w", "/static/41f9e889e80b620da046fd13c71397de/265b4/mieko-matsumoto.webp 1280w", "/static/41f9e889e80b620da046fd13c71397de/ed2e8/mieko-matsumoto.webp 1920w", "/static/41f9e889e80b620da046fd13c71397de/6b78f/mieko-matsumoto.webp 2400w"],
          "sizes": "(max-width: 1280px) 100vw, 1280px",
          "type": "image/webp"
        }}></source>{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/41f9e889e80b620da046fd13c71397de/9328e/mieko-matsumoto.jpg 320w", "/static/41f9e889e80b620da046fd13c71397de/f24a5/mieko-matsumoto.jpg 640w", "/static/41f9e889e80b620da046fd13c71397de/36316/mieko-matsumoto.jpg 1280w", "/static/41f9e889e80b620da046fd13c71397de/18346/mieko-matsumoto.jpg 1920w", "/static/41f9e889e80b620da046fd13c71397de/29666/mieko-matsumoto.jpg 2400w"],
          "sizes": "(max-width: 1280px) 100vw, 1280px",
          "type": "image/jpeg"
        }}></source>{`
        `}<img parentName="picture" {...{
          "className": "gatsby-resp-image-image",
          "src": "/static/41f9e889e80b620da046fd13c71397de/36316/mieko-matsumoto.jpg",
          "alt": "mieko matsumoto",
          "title": "mieko matsumoto",
          "loading": "lazy",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          }
        }}></img>{`
      `}</picture>{`
    `}</span>
    <TextWorkFrame type="matsumoto" mdxType="TextWorkFrame" />
    <Caption before={`作品`} medium={`写真　4点一組、2020年`} mdxType="Caption">
  《いつか　私も　見たい　もの》　
    </Caption>
    <BpHr type={`dot`} mdxType="BpHr" />
    <LinkButton to={`/works/takayuki-yamamoto`} mdxType="LinkButton">作品: 山本高之</LinkButton>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      